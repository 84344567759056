<template>
  <div>
    <Builder :blocks="blocks" :context="context" />
    <NuxtChild />
  </div>
</template>

<script>
import Builder from '../../../components/builder/Builder'
import pageBuilder from '../../../components/mixins/pageBuilder.js'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'trainers',
})

export default {
  name: 'TrainerList',
  components: {
    Builder,
  },
  mixins: [mixinPageBuilder],
  props: ['context'],
  meta: {
    theme: 'dark',
  },
}
</script>
